import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled/macro";
import { AppContext } from "Components/AppContext";
import Icon, { IconSVG } from "Elements/Icon";

export const StepNavigator = styled.div`
  display: inline-block;
  position: absolute;
  z-index: 20;
  top: 18px;
  left: ${({ theme }) => theme.space[3]};
  font-size: 3rem;
  transition: color ${({ theme }) => theme.misc.transition};
  cursor: pointer;
  user-select: none;

  ${({ theme }) => theme.mediaQuery.md} {
    display: none;
  }

  ${({ direction, theme }) =>
    direction === "next" &&
    `
    left: auto;
    right: ${theme.space[3]};
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(0, 0, 0, 0) !important;
    cursor: not-allowed;
  `};

  ${IconSVG} {
    ${({ direction }) => direction === "next" && `transform: rotate(180deg);`}
  }
`;

export default ({ direction = "next" }) => {
  const { appState } = useContext(AppContext);
  const [disabled, setDisabled] = useState(
    (direction === "previous" && appState.currentStep <= 0) ||
      (direction === "next" && appState.currentStep >= appState.totalSteps - 1)
  );

  const checkDisability = useCallback(() => {
    if (
      (direction === "previous" && appState.currentStep <= 0) ||
      (direction === "next" && appState.currentStep >= appState.totalSteps - 1)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [appState.currentStep, appState.totalSteps, direction]);

  useEffect(() => {
    checkDisability();
  }, [appState.currentStep, checkDisability]);

  const changeStep = (wantedStep = false) => {
    let targetStep = wantedStep;

    if (!targetStep) {
      if (direction === "previous") {
        targetStep = appState.currentStep - 1;
      } else if (direction === "next") {
        targetStep = appState.currentStep + 1;
      }
    }

    appState.setCurrentStep(targetStep);
  };

  const onClick = () => {
    if (!disabled) changeStep();
    checkDisability();
  };

  return (
    <StepNavigator direction={direction} disabled={disabled} onClick={onClick}>
      <Icon name="chevron" />
    </StepNavigator>
  );
};
