export default [
  {
    title: "Start",
    fields: [
      {
        title: "Kund",
        id: "client",
        description: "Vilken kund eller varumärke gäller det?",
        type: "text",
        placeholder: "Skriv här...",
      },
      {
        title: "Projektnamn",
        id: "project",
        description: "Vad är vårt interna namn på projektet?",
        type: "text",
        placeholder: "Skriv här...",
      },
      {
        title: "Uppdrag",
        id: "title",
        description: "Vad är det som ska göras?",
        type: "textarea",
        placeholder: "Skriv här...",
      },
      {
        title: "Referenser",
        description:
          "Vad har liknande riktning, känsla eller stil som det vi ska göra nu? (Kan vara allt från egna projekt till länkar/youtubeklipp/moodboard.)",
        type: "textarea",
        placeholder: "Skriv här...",
      },
    ],
  },
  {
    title: "Information",
    fields: [
      {
        title: "Deadline",
        description: "Vilket eller vilka datum behöver vi förhålla oss till?",
        type: "text",
        placeholder: "Skriv här...",
      },
      {
        title: "Leveransformat",
        description: "Hur ska det levereras? (Filformat, program, media osv.)",
        type: "text",
        placeholder: "Skriv här...",
      },
      {
        title: "Budget",
        description:
          "Vad vet vi om budget? (Storlek/spann, vad är sagt om externa kostnader som t.ex. annonsering/domäner/tryck osv.)",
        type: "textarea",
        placeholder: "Skriv här...",
      },
      {
        title: "Kontakt hos kund",
        description: "Vem är vår huvudsakliga kontaktperson hos kunden?",
        type: "text",
        placeholder: "Skriv här...",
      },
    ],
  },
  {
    title: "Ansvariga och förutsättningar",
    fields: [
      {
        title: "Ansvariga internt",
        description:
          "Vem äger projektet? Vem projektleder? Vem är kundansvarig?",
        type: "text",
        placeholder: "Skriv här...",
      },
      {
        title: "Kompetenser",
        description: "Vilka kompetenser från Beyond tror vi behövs?",
        type: "textarea",
        placeholder: "Skriv här...",
      },
      {
        title: "Underlag",
        description:
          "Var finns det vi behöver? Till exempel typsnitt, logo, lösenord etc.",
        type: "textarea",
        placeholder: "Skriv här...",
      },
    ],
  },
  {
    title: "Övrigt och slutför",
    fields: [
      {
        title: "Övrigt",
        description:
          "Om det finns övrig information som inte passar in någon annanstans, skriv den här.",
        type: "textarea",
        placeholder: "Skriv här...",
      },
    ],
  },
];
