import React, { useContext, useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { AppContext } from "Components/AppContext";

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 3px;
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => `${progress * 100}%`};
  height: 100%;
  background: ${({ theme }) => theme.colors.brand};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: width ${({ theme }) => theme.misc.slideTransition};
  transition-timing-function: ease-out;
`;

export default () => {
  const { appState } = useContext(AppContext);
  const { totalSteps, currentStep } = appState;
  const progress = (currentStep + 1) / totalSteps;

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasMounted(true);
    }, 1000);
  }, [setHasMounted]);

  return (
    <ProgressBarContainer>
      <ProgressBar progress={hasMounted ? progress : 0} />
    </ProgressBarContainer>
  );
};
