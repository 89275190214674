import React from "react";
import { css } from "@emotion/core";

export const primaryButton = (theme, test) => css`
  display: inline-block;
  padding: 10px 48px 13px;
  background: #000;
  color: #fff;
  appearance: none;
  font-size: 1.125rem;
  font-weight: 400;
  font-family: "PlainBlack", sans-serif;
  border: none;
  border-radius: 2rem;
  transition: background-color ${theme.misc.transition},
    transform ${theme.misc.transition};
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }

  &:active {
    background: #000 !important;
    transform: scale(0.96);
  }

  &:focus {
    /* background-color: ${theme.colors.brand}; */
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${theme.colors.greyLight} !important;

    &:hover,
    &:active,
    &:focus {
      background-color: ${theme.colors.greyLight} !important;
    }
  }
`;

export default ({ elem = "a", href = "#", text = "", ...restAttr }) => {
  if (elem === "a") {
    return (
      <a href={href} css={primaryButton} {...restAttr}>
        {text}
      </a>
    );
  } else if (elem === "button") {
    return (
      <button css={primaryButton} {...restAttr}>
        {text}
      </button>
    );
  }
};
