import React from "react";
import Header from "Parts/Header";
import Content from "Parts/Content";
import posed, { PoseGroup } from "react-pose";

const ContentPosed = posed("div")({
  enter: {
    opacity: 1,
    scale: 1,
    transition: {
      opacity: {
        duration: 200,
      },
      scale: {
        duration: 300,
      },
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      opacity: {
        duration: 200,
      },
      scale: {
        duration: 300,
      },
    },
  },
});

function App() {
  return (
    <>
      <Header />
      <PoseGroup>
        <ContentPosed key="content">
          <Content />
        </ContentPosed>
      </PoseGroup>
    </>
  );
}

export default App;
