import React, { useContext, useState } from "react";
import styled from "@emotion/styled/macro";
import { AppContext } from "Components/AppContext";
import formData from "@root/src/formData";
import Field from "Blocks/Field";
import { primaryButton } from "Elements/Button";
import KeySequence from "Helpers/KeySequence";
// import FormEnding from "Parts/FormEnding";
import posed, { PoseGroup } from "react-pose";

export const SlidingForm = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 120px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    z-index: 15;
  }

  &::after {
    left: auto;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  ${({ theme }) => theme.mediaQuery.md} {
    &::before,
    &::after {
      display: none;
    }
  }

  .slide-header {
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`;

export const FormSlide = styled.div`
  position: relative;
  z-index: 10;
  padding: 0 140px 160px;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 0 ${({ theme }) => theme.space[3]} 160px;
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }

  .step-number {
    font-family: "PlainBold", sans-serif;
    display: block;
  }
`;

const formSlideXSpringSettings = {
  type: "spring",
  stiffness: 190,
  damping: 18,
  mass: 0.7,
};

const FormSlidePosed = posed("div")({
  left: {
    x: "-100%",
    opacity: 0,
    transition: {
      x: formSlideXSpringSettings,
    },
  },
  inView: {
    x: 0,
    opacity: 1,
    transition: {
      x: formSlideXSpringSettings,
    },
  },
  right: {
    x: "100%",
    opacity: 0,
    transition: {
      x: formSlideXSpringSettings,
    },
  },
});

export const Button = styled.button`
  position: absolute;
  left: 50%;
  bottom: ${({ theme }) => theme.space[8]};
  transform: translateX(-50%);

  &:active {
    transform: translateX(-50%) scale(0.96) !important;
  }
`;

export default () => {
  const { appState } = useContext(AppContext);
  const { currentStep, totalSteps, setCurrentStep, authenticated } = appState;
  const [prevStep, setPrevStep] = useState(0);

  const [preEnterPose, setPreEnterPose] = useState("right");
  const [exitPose, setExitPose] = useState("left");

  const [buttonText, setButtonText] = useState("Slutför och ladda ner pdf");

  const download = () => {
    const clientName = appState.fields["0-0"]
      ? appState.fields["0-0"].value
      : "Kund_ej_angiven";
    const projectTitle = appState.fields["0-1"]
      ? appState.fields["0-1"].value
      : "Uppdrag_ej_angivet";

    const filename = `${clientName}:${projectTitle}`.replace(/\s+/g, ":");

    appState.briefPDF.download(filename);

    setButtonText("Pdf nedladdad");
    setTimeout(() => {
      setButtonText("Slutför och ladda ner pdf");
    }, 3000);
  };

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  const stepForward = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      setTimeout(() => {
        scrollToTop();
      }, 500);
    }
  };

  const stepBackward = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setTimeout(() => {
        scrollToTop();
      }, 500);
    }
  };

  const onButtonClick = (e) => {
    e.target.blur();
    setTimeout(() => {
      stepForward();
    }, 50);
  };

  const formSlides = formData.map((slide, index) => {
    const fieldGroup = slide.fields
      ? slide.fields.map((field, fieldIndex) => {
          return (
            <Field
              key={fieldIndex}
              data={field}
              index={fieldIndex}
              slideIndex={index}
            />
          );
        })
      : null;

    return currentStep === index ? (
      <FormSlidePosed key={index}>
        <FormSlide>
          <div className="slide-header">
            <span className="step-number">
              Steg {currentStep + 1} av {totalSteps}
            </span>
            <h2>{slide.title}</h2>
            {slide.description && <p>{slide.description}</p>}
          </div>
          {slide.formEnding && !authenticated ? null : fieldGroup}
          {index + 1 < formData.length ? (
            <Button css={primaryButton} onClick={onButtonClick}>
              Fortsätt
            </Button>
          ) : (
            <Button css={primaryButton} onClick={download}>
              {buttonText}
            </Button>
          )}
          {/* {slide.formEnding && <FormEnding />} */}
        </FormSlide>
      </FormSlidePosed>
    ) : null;
  });

  if (currentStep !== prevStep) {
    if (currentStep < prevStep) {
      setPreEnterPose("left");
      setExitPose("right");
    } else if (currentStep > prevStep) {
      setPreEnterPose("right");
      setExitPose("left");
    }
    setPrevStep(currentStep);
  }

  return (
    <SlidingForm>
      <KeySequence keys={[39]} callback={stepForward} />
      <KeySequence keys={[37]} callback={stepBackward} />
      <PoseGroup
        preEnterPose={preEnterPose}
        enterPose="inView"
        exitPose={exitPose}
      >
        {formSlides}
      </PoseGroup>
    </SlidingForm>
  );
};
