/* eslint no-unused-vars: 0 */
import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { AppContext } from "Components/AppContext";
// import BeyondLogo from "Assets/BeyondLogo.svg";
import { Container } from "Helpers/Commons";
import ProgressBar, { ProgressBarContainer } from "Blocks/ProgressBar";
import IconButton, { IconButton as StyledIconButton } from "Blocks/IconButton";
import Preview from "Parts/Preview";
import { MediumUp } from "Helpers/Breakpoints";

const Header = styled("div")`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 200;
  height: ${({ theme }) => theme.sizes.headerHeight};
  display: flex;
  align-items: center;

  ${ProgressBarContainer} {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const StyledContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: -1px;
`;

const LogoSVG = styled("svg")`
  display: block;
  width: 110px;
  height: 26px;
`;

export const ActionButtonsSegment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${StyledIconButton} {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.space[3]};
    }
  }
`;

const ActionButtonsStyled = styled.div`
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ActionButtonsSegment} {
    margin-right: ${({ theme }) => theme.space[3]};
    padding-right: ${({ theme }) => theme.space[3]};
    border-right: 1px solid #aaa;

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border: none;
    }
  }
`;

export default () => {
  const { appState } = useContext(AppContext);
  const {
    totalSteps,
    setCurrentStep,
    setFields,
    fields,
    briefPDF,
    // authenticated,
    // sandboxMode,
    setSandboxMode,
  } = appState;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [actionButtonsMode, setActionButtonsMode] = useState("ordinary");
  const [newBriefConfirmed, setNewBriefConfirmed] = useState(false);

  // const goToEnd = () => {
  //   setCurrentStep(totalSteps);
  // };

  const newBrief = () => {
    if (newBriefConfirmed) {
      setNewBriefConfirmed(false);
      localStorage.removeItem("fields");
      setFields({});
      setCurrentStep(0);
    } else {
      setNewBriefConfirmed(true);
    }
  };

  const preview = () => {
    setPreviewOpen(!previewOpen);
  };

  const download = () => {
    const clientName = appState.fields["0-0"]
      ? appState.fields["0-0"].value
      : "Kund_ej_angiven";
    const projectTitle = appState.fields["0-1"]
      ? appState.fields["0-1"].value
      : "Uppdrag_ej_angivet";

    const filename = `${clientName}:${projectTitle}`.replace(/\s+/g, ":");

    briefPDF.download(filename);
  };

  // const enterSandboxMode = () => {
  //   setSandboxMode(true);
  //   setCurrentStep(1);
  // };

  useEffect(() => {
    if (previewOpen) {
      setActionButtonsMode("preview");
    } else {
      setActionButtonsMode("ordinary");
    }
  }, [previewOpen]);

  return (
    <>
      <Header>
        <StyledContainer>
          <LogoSVG>
            <use xlinkHref={`#BeyondLogo`} />
          </LogoSVG>
          <MediumUp>
            <ActionButtonsStyled>
              {actionButtonsMode === "preview" ? (
                <IconButton icon="times" text="Stäng" onClick={preview} />
              ) : (
                <>
                  <ActionButtonsSegment>
                    <IconButton
                      icon="redo"
                      text={!newBriefConfirmed ? "Ny brief" : "Säker?"}
                      onClick={newBrief}
                    />
                  </ActionButtonsSegment>
                  <ActionButtonsSegment>
                    <IconButton
                      icon="search"
                      text="Förhandsgranska"
                      onClick={preview}
                    />
                    <IconButton
                      icon="download"
                      text="Ladda ner"
                      onClick={download}
                    />
                  </ActionButtonsSegment>
                  {/* <ActionButtonsSegment>
                    <IconButton
                      icon="success-file"
                      text="Slutför"
                      onClick={goToEnd}
                    />
                  </ActionButtonsSegment> */}
                </>
              )}
            </ActionButtonsStyled>
          </MediumUp>
        </StyledContainer>
        <ProgressBar />
      </Header>
      <Preview open={previewOpen} setOpen={setPreviewOpen} />
    </>
  );
};
